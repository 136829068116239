import { useEffect } from "react";

const Jubol = () =>{
 
    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://s3-ap-southeast-1.amazonaws.com/djubo-static/static/widget/js/widget.min.2.0.js";
        script.async = true;
    
        document.body.appendChild(script);
        
        script.onerror = () => console.error("Script failed to load");
    
        return () => {
          if (script.parentNode) {
            script.parentNode.removeChild(script);
          }
        }

        },[]);
      
    return <div id="book-now-widget" style={{'float':'left'}}>
    <div id="BEx4IDaY3bWD">
    <div id="BEx4IDaY3bWR" class="BEx4ZXaY3bWR"></div>
    <input type ="hidden" value="-6uQEKs5nCdn9-6Iu4CLRQ" id="BEx4ZXaPkNmGuid"/>
    <input type ="hidden" value="tu17DnD7VEuctHBOC_fC1A" id="BEx4ZYaLkProGuid"/>
    </div>	
    </div>

}

export default Jubol